import React, { Component } from 'react';
import styled from 'styled-components';
import Header from '../../components/header';
import Footer from '../../components/footer';
import userServices from '../../services/userServices';
import { RfiCta } from '../../components/PrimaryCTA/PrimaryCTA';
import HeroImage from '../../images/banner-new.jpg';

const Layout = styled.div`
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 15px;
`;
const ContactWrapper = styled.main`
  background: #f6f6fa;
  padding-bottom: 50px;
  img {
    width: 100%;
    height: 170px;
  }
  .ctr {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
    padding: 30px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
    margin-top: -80px;
    z-index: 1;
    position: relative;
    text-align: center;
    h5 {
      font-size: 34px;
      margin-bottom: 25px;
    }
    p {
      margin: 0 0 20px;
      font-size: 18px;
      line-height: 1.5;
      a {
        color: #e3383a;
      }
    }
  }
`;

export default class Contact extends Component {
  state = {
    isUserLoggedIn: false,
    userMobile: null
  };

  async componentWillMount() {
    if (typeof window !== 'undefined') {
      if (window.localStorage.getItem('sessionToken')) {
        this.setState({ isXhrInProgress: true });
        let checkUser = await userServices.authenticateUser();
        this.setState({ isXhrInProgress: false });
        if (checkUser && checkUser.mobile && checkUser.mobile !== '') {
          window.localStorage.setItem('userMobile', checkUser.mobile);
          this.setState({ userMobile: checkUser.mobile, isUserLoggedIn: true });
        } else {
          window.localStorage.removeItem('sessionToken');
          window.localStorage.removeItem('userMobile');
        }
      }
    }
  }

  contactUsClick = () => {
    userServices.sendAnalyticsEvents({
      category: 'Conatct Us',
      action: 'Clicks',
      label: 'Mail To'
    });
  };

  render() {
    const { isUserLoggedIn, userMobile } = this.state;
    return (
      <div>
        <Header isUserLoggedIn={isUserLoggedIn} userMobile={userMobile} />
        <ContactWrapper>
          <img src={HeroImage} alt='' />
          <Layout>
            <div className='ctr' onClick={this.contactUsClick}>
              <h5>Contact us</h5>
              <p>
                For any queries regarding National Freight Index, you can mail us at:
                <br />
                <a href='mailto:nfi@rivigo.com'>nfi@rivigo.com</a>
              </p>
              <a href='mailto:nfi@rivigo.com' style={{ width: '120px' }}>
                <RfiCta style={{ borderRadius: '46px' }}>Mail us</RfiCta>
              </a>
            </div>
          </Layout>
        </ContactWrapper>
        <Footer />
      </div>
    );
  }
}
