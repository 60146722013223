import React from 'react';
import styled from 'styled-components';
import EmailAlert from './EmailAlert';
import { FacebookIcon, LinkedinIcon, TwitterIcon, InstagramIcon, YoutubeIcon } from '../custom-icons';
import { device } from '../constants/device';

const FooterWrpr = styled.footer`
  background-color: #57585a;
  .layout {
    width: 100%;
    max-width: 1230px;
    margin: 0 auto;
    padding: 0 15px;
  }
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  .topSec {
    padding: 60px 0 30px;
  }
  .midSec {
    padding: 30px 0;
  }
  .btmSec {
    margin: 0;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #ffffff80;
    padding: 30px 0 60px;
    font-size: 14px;
    color: #ffffff;
  }
  @media ${device.tablet} {
    .midSec {
      padding: 20px 0;
      flex-direction: column;
      .tabColstyle {
        max-width: 100%;
        margin-bottom: 25px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    .topSec {
      padding: 40px 0 20px;
    }
    .btmSec {
      padding: 20px 0 40px;
      justify-content: center;
      p {
        padding: 2px 15px;
        font-size: 13px;
      }
    }
  }
`;
const FooterCol = styled.div`
  min-width: 25%;
  max-width: 25%;
  padding: 0 15px;
  ul {
    list-style: none;
    li {
      margin: 10px 0;
    }
  }
  a,
  p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    font-weight: 400;
    transition: all 0.4s;
  }
  a {
    &:hover {
      color: #ffffff;
    }
  }
  h5 {
    margin-bottom: 15px;
    font-size: 18px;
    color: #ffffff;
  }
  &.w50 {
    min-width: 25%;
    max-width: 25%;
  }
  .socialIcons {
    margin-top: 10px;
    a {
      font-size: 22px;
      vertical-align: middle;
      margin-right: 30px;
    }
    a:hover {
      svg {
        transform: scale(1.2);
        transition: all 0.2s ease-in-out;
      }
    }
  }
  @media ${device.tablet} {
    min-width: 50%;
    max-width: 50%;
    h5 {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
`;
const Footer = () => {
  return (
    <FooterWrpr>
      <div className='layout'>
        {/* <div className='row topSec'>
          <FooterCol>
            <ul>
              <li>
                <a target='_blank' rel='noopener noreferrer' href='https://www.rivigo.com/products#relay-full-truck'>
                  Relay Full Truck
                </a>
              </li>
              <li>
                <a target='_blank' rel='noopener noreferrer' href='https://www.rivigo.com/products#relay-express-cargo'>
                  Relay Express Cargo
                </a>
              </li>
              <li>
                <a target='_blank' rel='noopener noreferrer' href='https://www.rivigo.com/products#relay-cold-chain'>
                  Relay Cold Chain
                </a>
              </li>
            </ul>
          </FooterCol>
          <FooterCol>
            <ul>
              <li>
                <a target='_blank' rel='noopener noreferrer' href='https://www.rivigo.com/products#freight-marketplace'>
                  Freight Marketplace
                </a>
              </li>
              <li>
                <a target='_blank' rel='noopener noreferrer' href='https://www.rivigo.com/industries'>
                  Industries
                </a>
              </li>
              <li>
                <a target='_blank' rel='noopener noreferrer' href='https://www.rivigo.com/safety'>
                  Safety
                </a>
              </li>
            </ul>
          </FooterCol>
          <FooterCol>
            <ul>
              <li>
                <a target='_blank' rel='noopener noreferrer' href='https://www.rivigo.com/insights'>
                  Insights
                </a>
              </li>
              <li>
                <a target='_blank' rel='noopener noreferrer' href='https://www.rivigo.com/labs'>
                  Labs
                </a>
              </li>
              <li>
                <a target='_blank' rel='noopener noreferrer' href='https://www.rivigo.com/careers'>
                  Careers
                </a>
              </li>
            </ul>
          </FooterCol>
          <FooterCol>
            <ul>
              <li>
                <a target='_blank' rel='noopener noreferrer' href='https://www.rivigo.com/about-us'>
                  About Us
                </a>
              </li>
              <li>
                <a target='_blank' rel='noopener noreferrer' href='https://www.rivigo.com/udaan'>
                  Partner
                </a>
              </li>
              <li>
                <a target='_blank' rel='noopener noreferrer' href='/Privacy_policy'>
                  Policies
                </a>
              </li>
              <li>
                <a target='_blank' rel='noopener noreferrer' href='/Terms_of_use'>
                  Terms of use
                </a>
              </li>
            </ul>
          </FooterCol>
        </div> */}
        <div className='row midSec'>
          <FooterCol className='tabColstyle'>
            <h5 style={{ color: '#ffffff' }}>About NFI</h5>
            <p>
              ​NFI is an open source data portal based on a proprietary algorithm that collects inputs from multiple
              data sources to give out the most accurate spot rates. It is a pivotal step in Rivigo’s journey of making
              logistics human by establishing trust and transparency in the Indian logistics ecosystem.
            </p>
          </FooterCol>
          <FooterCol className='w50 tabColstyle'>
            <h5>Sign up for email alerts</h5>
            <p>Get daily freight rates, monthly insights and more directly in your mailbox</p>
            <EmailAlert />
          </FooterCol>
          <FooterCol className='w50 tabColstyle'>
            <h5>Terms and conditions</h5>
            <a target='_blank' rel='noopener noreferrer' href='/Privacy_policy'>
              <p style={{ marginBottom: '15px' }}>Policies</p>
            </a>
            <a target='_blank' rel='noopener noreferrer' href='/Terms_of_use'>
              <p>Terms of use</p>
            </a>
          </FooterCol>
          <FooterCol className='tabColstyle'>
            <h5>Connect</h5>
            <p>Stay connected with us</p>
            <p className='socialIcons'>
              <a href='https://www.facebook.com/RivigoTech' target='_blank' rel='noopener noreferrer'>
                <FacebookIcon color='#ffffff' />
              </a>
              <a href='https://www.linkedin.com/company/rivigo/' target='_blank' rel='noopener noreferrer'>
                <LinkedinIcon color='#ffffff' />
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://twitter.com/share?url=https://nationalfreightindex.co.in'
                className='social'>
                <TwitterIcon color='#ffffff' />
              </a>
              <a href='https://www.instagram.com/_rivigo_/' target='_blank' rel='noopener noreferrer'>
                <InstagramIcon color='#ffffff' />
              </a>
              <a
                href='https://www.youtube.com/channel/UCHHWogkJl0zh5Gi8TniFXxg'
                target='_blank'
                rel='noopener noreferrer'>
                <YoutubeIcon color='#ffffff' />
              </a>
            </p>
          </FooterCol>
        </div>
        <div className='row btmSec'>
          <p>MAKING LOGISTICS HUMAN</p>
          <p>&copy; 2022. Rivigo Services Pvt Ltd</p>
        </div>
      </div>
    </FooterWrpr>
  );
};

export default Footer;
