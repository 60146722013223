import React from 'react';
import styled from 'styled-components';
import iffun from '@bit/rivigo.ui.utils.iffun';
import { SendIcon, TickCircleIcon } from '../custom-icons';
import { device } from '../constants/device';
import { DialogWrapper, Dialog } from '../components-pure/Dialog';
import userServices from '../services/userServices';

const EmailAlertWrpr = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 90%;
  margin-top: 20px;
  background: #ffffff;
  * {
    -webkit-appearance: none;
    border: none;
    outline: none;
    flex-grow: 1;
  }
  button {
    width: 40px;
    max-width: 40px;
    background: #e3383a;
    border: 2px solid #fff;
    color: white;
    cursor: pointer;
    padding: 6px 10px;
    font-size: 18px;
  }
  input {
    background: #ffffff;
    padding: 10px;
    font-size: 14px;
  }
  @media ${device.tablet} {
    width: 100%;
    margin-top: 10px;
  }
`;

const StyledDialog = styled(Dialog)`
  &&& {
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    max-width: 310px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p {
      color: #2a2a2a;
      font-weight: 700;
      font-size: 16px;
    }
    svg {
      margin-bottom: 20px;
      color: #19b985;
      font-size: 60px;
    }
  }
`;
class EmailAlert extends React.Component {
  state = {
    email: '',
    showPopUp: false
  };

  sendEmail = async () => {
    //TODO add send email API
    if (this.state.email) {
      await userServices.sendEmailAlerts(this.state.email);
      this.setState({ showPopUp: true });
      setTimeout(() => {
        this.setState({ showPopUp: false, email: '' });
      }, 2000);
      userServices.sendAnalyticsEvents({
        category: 'Redirection',
        action: `Clicks-Subscribe to newsletter`,
        label: `Success`
      });
    }
  };

  onEmailChange = (e) => {
    if (e.target.value.length === 0) {
      return;
    }
    userServices.sendAnalyticsEvents({
      category: 'Redirection',
      action: `Clicks-Subscribe to newsletter`,
      label: `Email: ${e.target.value}`
    });
    this.setState({
      email: e.target.value
    });
  };

  restrictSubmit = (e) => {
    e.preventDefault();
  };

  handleKeyDown = (e) => {
    if (String(e.keyCode) === '13') {
      if (this.state.email) {
        this.sendEmail();
      }
    }
  };

  render() {
    return (
      <EmailAlertWrpr>
        <input
          placeholder='Email ID'
          name='email'
          value={this.state.email}
          onChange={(e) => this.onEmailChange(e)}
          onKeyDown={(e) => this.handleKeyDown(e)}
        />
        <button onClick={this.sendEmail}>
          <SendIcon color='#ffffff' />
        </button>
        {iffun(
          this.state.showPopUp === true,
          <DialogWrapper>
            <StyledDialog>
              <TickCircleIcon />
              <p>Successfully Subscribed </p>
            </StyledDialog>
          </DialogWrapper>,
          null
        )}
      </EmailAlertWrpr>
    );
  }
}

export default EmailAlert;
